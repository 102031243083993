import React from "react";
import { FormikProps } from "formik";
import { animated } from "@react-spring/web";

import { ArrowLeft, LogoSymbol2 } from "@app/components/Icons";
import { Tags } from "../Components/Tags";
import { SubmitButton } from "../Components/SubmitButton";

interface Props extends React.ComponentProps<"div"> {
  style: any;
  formik: FormikProps<any>;
  finish: (e: any) => void;
  gotoNextPage: (e: any) => void;
  submitButtonStyles: any;
}

const EnterDetails = ({
  style,
  formik,
  finish,
  gotoNextPage,
  submitButtonStyles
}: Props) => (
  <animated.div className="feedback-page" style={style}>
    <LogoSymbol2 white="#2E2626" />
    <h1 className="feedback-h1">Hi there. </h1>
    <p className="feedback-p">Got a question? Send us a message!</p>
    <textarea
      className="feedback-input"
      value={formik.values.message}
      onChange={(e) => formik.setFieldValue("message", e.target.value)}
      placeholder="Type here..."
    />
    <Tags setSelectedTags={(tags) => formik.setFieldValue("tags", tags)} />
    <div className="feedback-footer">
      <SubmitButton
        onClick={(e) => {
          if (formik.values?.email) {
            gotoNextPage(e);
            finish(e);
          } else {
            gotoNextPage(e);
          }
        }}
        style={submitButtonStyles}
      >
        Next
        <ArrowLeft
          className="_hFlip _ml5"
          stroke="white"
          width="20"
          height="20"
        />
      </SubmitButton>
    </div>
  </animated.div>
);

export { EnterDetails };
