import React from "react";

const Terms = () => {
  return (
    <main>
      <section className="terms">
        <h1>Terms</h1>
      </section>
    </main>
  );
};

export { Terms };
