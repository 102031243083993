import React from "react";
import { Lock, Unlocked } from "@app/components/Icons";

interface Props {
  title?: string;
  value: string;
  placeholder?: string;
  isLocked?: boolean;
  onChange: (e) => void;
}

const TextInput = ({ title, value, onChange, isLocked }: Props) => {
  return (
    <div className="formItem-inputContainer">
      <input
        type="text"
        value={value}
        className="formItem-input"
        onFocus={() => {}}
        onChange={onChange}
        disabled={isLocked}
      />
      <div className="formItem-lock">
        {isLocked ? (
          <Lock width={12} height={12} strokeWidth={2.5} />
        ) : (
          <Unlocked width={12} height={12} strokeWidth={2.5} />
        )}
      </div>
    </div>
  );
};
export { TextInput };
