import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useProject } from "@app/utils/query";
import { Button, Tabs } from "@app/components";

import { __DEV__ } from "@app/utils/errors";
import { useJobTest } from "@app/utils/hooks";

import { JobLogEntry, Project } from "@app/types";

import { Header } from "../_Shared";
import { GameJobs } from "./Jobs";
import { GameBuilds } from "./Builds";
import { GameOverview } from "./Overview";
import { GameCredentials } from "./Credentials/index";

enum TABS {
  OVERVIEW = "OVERVIEW",
  JOBS = "JOBS",
  BUILDS = "BUILDS",
  CREDENTIALS = "CREDENTIALS"
}

type Params = {
  projectId: Project["id"];
  jobId?: JobLogEntry["id"];
  tab?: TABS;
};

const Game = () => {
  const { jobId, projectId, tab } = useParams<Params>();
  const { data: project } = useProject(projectId);
  const { isTesting, handleTestJobClick: handleTest } = useJobTest(projectId);
  const [selectedTab, setSelectedTab] = useState(
    tab?.toUpperCase() || TABS.JOBS
  );

  if (!project) return null;

  return (
    <>
      <Header title={project.name} />
      <main className="admin-main">
        <div className="_flex _flex-spaceBetween _flex-start">
          <Tabs
            tabs={TABS}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          {__DEV__ && (
            <Button size="sm" isLoading={isTesting} onClick={handleTest}>
              Run Test
            </Button>
          )}
        </div>
        {selectedTab === TABS.OVERVIEW && (
          <GameOverview projectId={projectId} />
        )}
        {selectedTab === TABS.JOBS && (
          <GameJobs projectId={projectId} jobId={jobId} />
        )}
        {selectedTab === TABS.BUILDS && <GameBuilds projectId={projectId} />}
        {selectedTab === TABS.CREDENTIALS && (
          <GameCredentials projectId={projectId} />
        )}
      </main>
    </>
  );
};

export { Game };
