import React, { useState } from "react";
import { Project } from "@app/types";

interface Props {
  projectId: Project["id"];
}

const GameOverview = ({ projectId }) => {
  return <div></div>;
};

export { GameOverview };
