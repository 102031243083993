import React, { useRef } from "react";
import classNames from "classnames";
import useMeasure from "react-use-measure";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "@react-spring/web";

import { useCopyToClipboard } from "@app/utils/hooks";
import { Button, Download, Expand, Link, Log } from "@app/components";

const JobContent = ({ project, job, isActive, isSubscribed }) => {
  const navigate = useNavigate();
  const fullScreenUrl = `/games/${project.id}/job/${job.id}/full`;

  const logRef = useRef<HTMLElement>(); // Log content for saving
  const { copyText, isCopying, isCopied } = useCopyToClipboard();

  const [ref, { height }] = useMeasure();
  const aniProps = useSpring({
    from: { height: 0 },
    to: { height: isActive ? height : 0 }
  });

  const downloadLog = () => {
    // Currently only download the part that has actually loaded
    // TODO: load all of the pages of the logfile first
    // also, display a loader
    const fileData = logRef.current?.innerText;
    const fileDataReversed = fileData.split("\n").reverse().join("\n");
    const blob = new Blob([fileDataReversed], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `log-${job.id?.substring(0, 5)}.txt`;
    link.href = url;
    link.click();
  };

  return (
    <animated.div
      style={aniProps}
      className={classNames("job-content", {
        "job-content--visible": isActive
      })}
    >
      <div ref={ref}>
        <Log
          projectId={project.id}
          jobId={job.id}
          logRef={logRef}
          isSubscribed={isSubscribed}
        />

        <footer className="job-footer">
          <Button
            variant="dark"
            href={fullScreenUrl}
            onClick={() => navigate(fullScreenUrl)}
          >
            <span className="_mr5">View fullscreen</span>
            <Expand width={14} stroke="#95a0aa" />
          </Button>
          <Button onClick={downloadLog} variant="dark" centeredLoader>
            <span className="_mr5">Download</span>
            <Download width={16} stroke="#95a0aa" />
          </Button>
          <Button
            onClick={() => copyText(window.location.host + fullScreenUrl)}
            variant="dark"
            isLoading={isCopying}
            centeredLoader
          >
            {isCopied && "Copied 👍"}
            {!isCopied && (
              <>
                <span className="_mr5">Link</span>
                <Link width={14} stroke="#95a0aa" />
              </>
            )}
          </Button>
          <div />
          <Button onClick={() => {}} variant="dark">
            ?
          </Button>
        </footer>
      </div>
    </animated.div>
  );
};

export { JobContent };
