import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="auth_footer">
      <div className="auth_footer_links">
        <Link to="/">Home</Link>
        {/*
        <Link to="/terms">Terms</Link>
        <Link to="/privacy">Privacy</Link>
        */}
      </div>
      © 2024
    </div>
  );
};

export { Footer };
