import React from "react";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

import {
  Button,
  Section,
  SectionHeader as Header,
  ShortName,
  Spinner,
  STATE_COLORS,
  Status,
  TimeAgo
} from "@app/components";
import { useMyRecentJobs } from "@app/utils/query";
import { Job } from "@app/types";
import { OsIcon } from "@app/components/Icons/_OsIcon";

const MyRecentJobs = () => {
  const { data, isLoading } = useMyRecentJobs();

  return (
    <Section variant="ghost">
      <Header>
        <div>Recent Jobs {isLoading && <Spinner />}</div>
        <Button onClick={() => {}} size="sm" variant="dark">
          Button
        </Button>
      </Header>

      <div className="list">
        {data?.map(({ id, status, type, project, updatedAt, details }: Job) => (
          <Link
            key={id}
            className="list-item list--jobs-recent"
            to={`/games/${project.id}/job/${id}`}
          >
            <ShortName name={project.name} className="list-icon" />
            <div>
              <span>{project.name}</span>
              <span> {details?.semanticVersion || "0.0.1"}</span>
              <OsIcon type={type} fill="#aaa" size={16} className="_ml5" />
            </div>
            <TimeAgo date={updatedAt} showIcon showAgo />
            <Status variant={STATE_COLORS[status]}>{status}</Status>
          </Link>
        ))}
      </div>
    </Section>
  );
};

export { MyRecentJobs };
