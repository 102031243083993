import React, { useEffect } from "react";
import classNames from "classnames";
import { DateTime } from "luxon";

import { Spinner } from "@app/components";
import { Job, Project } from "@app/types";
import { useJobLogsInfinite } from "@app/utils/query";
import { useTopReached } from "@app/utils/hooks/useTopReached";

import { LevelDot } from "./LevelDot";
import { JobLogListener, useWebSocket } from "@app/utils/hooks";

interface Props {
  projectId: Project["id"];
  jobId: Job["id"];
  isSubscribed?: boolean;
  showEntireLog?: boolean;
  logRef?: any;
}

const Log = ({
  projectId,
  jobId,
  isSubscribed,
  showEntireLog,
  logRef
}: Props) => {
  // Only listen to websocket messages if the job is pending or processing
  const listeners = isSubscribed ? [new JobLogListener(projectId, jobId)] : [];
  useWebSocket(listeners);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useJobLogsInfinite({ projectId, jobId });

  const { ref, onScrollEvent } = useTopReached(
    hasNextPage,
    fetchNextPage,
    data?.pageParams?.length
  );

  const noLog = !data?.pages?.[0]?.[0]?.id;

  // Fetch all pages in fullscreen mode and keep the
  // screen on the bottom of the page
  useEffect(() => {
    if (showEntireLog && hasNextPage) fetchNextPage();
  }, [data, showEntireLog, hasNextPage]);

  return (
    <div className="_relative" ref={logRef}>
      {isFetchingNextPage && <Spinner className="job-log-spinner" />}
      <div
        ref={ref}
        className={classNames(
          "job-log",
          { "job-log--noLogData": noLog },
          { "job-log--fullScreen": showEntireLog }
        )}
        onScroll={onScrollEvent}
      >
        {noLog && <div className="job-log-text">Nothing logged yet</div>}
        {data?.pages?.map((page) => {
          return page.map(
            ({ id, createdAt, details, level, message, sentAt, stage }) => {
              const timeSent = DateTime.fromISO(sentAt);
              return (
                <div key={id} className="job-log-line">
                  <span className="job-log-hilight">
                    {timeSent.toLocaleString(DateTime.TIME_WITH_SECONDS)}{" "}
                  </span>
                  <LevelDot level={level} />
                  <span className="job-log-stage">{stage}</span>
                  <span className="_hidden"> </span>
                  <span className="job-log-text">{message}</span>
                </div>
              );
            }
          );
        })}
      </div>
    </div>
  );
};

export { Log };
