import classNames from "classnames";
import { useEffect } from "react";
import { useTags } from "../Hooks/useFeedbackTags";

const TAGS = [
  { title: "Hello", color: "orange" },
  { title: "Great", color: "green" },
  { title: "Question", color: "pink" },
  { title: "Bug", color: "red" }
];

interface Props {
  setSelectedTags: any;
}

const Tags = ({ setSelectedTags, ...props }: Props) => {
  const { tags, toggleTag } = useTags(TAGS);

  useEffect(() => {
    const selectedTags = tags
      .map((t) => t.isSelected && t.title)
      .filter((e) => e);
    setSelectedTags(selectedTags);
  }, [tags]);

  return (
    <div className="feedback-tags">
      <div className="feedback-tagLabel">Tags</div>
      {tags?.map(({ title, color, isSelected }) => (
        <a
          key={title}
          href="#"
          onClick={(e) => toggleTag(e, title)}
          className={classNames("feedback-tag", `feedback-tag--${color}`, {
            "feedback-tag--isSelected": isSelected
          })}
        >
          {title}
        </a>
      ))}
    </div>
  );
};

export { Tags };
