import { useState } from "react";

export interface InputTag {
  title: string;
  color: string;
}

export interface Tag extends InputTag {
  isSelected: boolean;
}

export const useTags = (tagList: InputTag[] = []) => {
  const initialTags = tagList.map((t) => ({ ...t, isSelected: false }));
  const [tags, setTags] = useState<Tag[]>(initialTags);

  const toggleTag = (e: React.MouseEvent<any>, title: string) => {
    e.preventDefault();
    const newTags: Tag[] = tags.map((t) => ({
      ...t,
      isSelected: t.title === title ? !t.isSelected : t.isSelected
    }));

    setTags(newTags);
  };

  return { tags, toggleTag };
};
