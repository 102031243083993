import React from "react";
import { LogoSymbol, LogoSymbol2 } from "@app/components/Icons";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

interface Props extends React.ComponentProps<"a"> {
  linkTo?: string;
}

const Logo = ({ linkTo = "/", className, ...props }: Props) => (
  <NavLink to={linkTo} className={classNames("logo", className)} {...props}>
    <LogoSymbol2 />
    <span className="logo-text">ShipThis</span>
  </NavLink>
);

export { Logo };
