import axios from "axios";
import React, { useState } from "react";
import { queryClient, getAuthHeaders, useJobs } from "@app/utils/query";
import { __DEV__ } from "@app/utils/errors";
import { API_URL, cacheKeys } from "@app/constants";
import { Project } from "@app/types";

const useJobTest = (projectId: Project["id"]) => {
  const [isTesting, setIsTesting] = useState(false);

  const handleTestJobClick = async () => {
    try {
      setIsTesting(true);
      const headers = getAuthHeaders();
      await axios.post(`${API_URL}/upload/${projectId}/test`, {}, { headers });
      // Removed as Websocket should fire to add new test jobs
      //queryClient.invalidateQueries({ queryKey: cacheKeys.jobs(projectId, 0) });
    } catch (error) {
      alert("Error testing job " + error.message);
    } finally {
      setIsTesting(false);
    }
  };

  return { isTesting, handleTestJobClick };
};

export { useJobTest };
