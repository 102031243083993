import {
  Project,
  PageAndSortParams,
  Job,
  CredentialPlatform
} from "@app/types";

const androidSetupStatus = (projectId: Project["id"]) => [
  "androidSetupStatus",
  projectId
];

const projects = (params: PageAndSortParams) => ["projects", params];
const myRecentJobs = ["myRecentJobs"];

const jobs = (projectId: Project["id"], params: PageAndSortParams) => [
  "jobs",
  projectId,
  params
];

const jobLogEntries = (
  jobId: Job["id"],
  cursor: string | unknown,
  paged: "plain" | "lastItem" | "infinite"
) => ["jobLogEntries", jobId, cursor, paged];

const project = (projectId: Project["id"]) => ["project", projectId];

const projectCredentials = (
  projectId: Project["id"],
  platform: CredentialPlatform | undefined,
  pageNumber: number
) => ["projectCredentials", projectId, pageNumber];

const userCredentials = (pageNumber: number) => ["userCredentials", pageNumber];

const docs = (file) => ["docs", file];

export const cacheKeys = {
  self: ["self"],
  myRecentJobs,
  isSignedIn: ["isSignedIn"],
  androidSetupStatus,
  jobs,
  jobLogEntries,
  project,
  projectCredentials,
  projects,
  userCredentials,
  docs
};
