import React, { useEffect } from "react";
import classNames from "classnames";
import Markdown from "react-markdown";
import { NavLink, useParams } from "react-router-dom";

import { Chevron, Spinner, Terminal } from "@app/components";
import { useCliReadMe, useScrollToAnchor } from "@app/utils/hooks";

const ToggleBox = ({ isActive }) => {
  return (
    <div
      className={classNames("docs-aside-toggle", {
        "docs-aside-toggle--active": isActive
      })}
    >
      <Chevron stroke="white" width={15} />
    </div>
  );
};

const Docs = () => {
  const { title = "index" } = useParams();
  const { data, isLoading, isSuccess } = useCliReadMe();

  console.log(data);

  useScrollToAnchor();

  return (
    <main>
      <section className="docs">
        <aside className="docs-aside">
          <NavLink to="/docs" key={title}>
            Shipthis
          </NavLink>
          {isSuccess &&
            Object.keys(data.commands)?.map((cmd) => (
              <div
                key={cmd}
                className={classNames("docs-item", {
                  "docs-item--active": cmd === title
                })}
              >
                <NavLink to={`/docs/${cmd}`} className="_flex">
                  <ToggleBox isActive={cmd === title} /> {cmd}
                </NavLink>
                <div className="docs-subItem">
                  {data.hierarchy[cmd].length > 1 &&
                    data.hierarchy[cmd].map((i) => (
                      <NavLink key={i} to={`#${i}`}>
                        — {i}
                      </NavLink>
                    ))}
                </div>
              </div>
            ))}
        </aside>
        <main className="docs-main">
          {isLoading && <Spinner />}
          {data?.commands?.[title]?.map((item) => (
            <div key={item.headline} id={item.titleParts[2]} className="_mb20">
              <Markdown key={item.headline} className="docs-contentItem">
                {`# ${item.headline}\n${item.description}`}
              </Markdown>
              <Terminal>{item.code}</Terminal>
            </div>
          ))}
        </main>
      </section>
    </main>
  );
};

export { Docs };
