import React from "react";
import { animated } from "@react-spring/web";

import { ArrowLeft, ChevronRight } from "@app/components/Icons";
import { SubmitButton } from "../Components/SubmitButton";

interface Props extends React.ComponentProps<"div"> {
  style: any;
  formik: any;
  gotoPrevPage: (e: any) => void;
  gotoNextPage: (e: any) => void;
  finish: (e: any) => void;
  submitButtonStyles: any;
}

const ExtraDetails = ({
  style,
  formik,
  gotoPrevPage,
  gotoNextPage,
  finish,
  submitButtonStyles
}: Props) => (
  <animated.div className="feedback-page" style={style}>
    <a href="#" onClick={gotoPrevPage} className="feedback-backButton">
      <ChevronRight className="_hFlip" />
      Back
    </a>
    <p className="feedback-p _mt15">
      Let us know your email so we can respond to you.
    </p>
    <input
      type="text"
      className="feedback-input"
      value={formik.values.email}
      onChange={(e) => formik.setFieldValue("email", e.target.value)}
      placeholder="Email address"
    />
    <div className="_flex-one"></div>
    <div className="_mb10">
      <label className="_flex _flex-center">
        <input
          type="checkbox"
          className="feedback-checkbox _mr5"
          checked={formik.values.sendDetails}
          onChange={(e) =>
            formik.setFieldValue("sendDetails", e.target.checked)
          }
        />
        <span>Send info along </span>
      </label>
    </div>
    <div className="feedback-footer">
      <SubmitButton onClick={finish} style={submitButtonStyles}>
        Next
        <ArrowLeft
          className="_hFlip _ml5"
          stroke="white"
          width="20"
          height="20"
        />
      </SubmitButton>
    </div>
  </animated.div>
);

export { ExtraDetails };
