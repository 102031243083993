import React, { useState } from "react";
import { Button, Terminal } from "@app/components";
import {
  Apple,
  Android,
  ArrowLeft,
  GodotLogo,
  Phone,
  Play,
  Smile,
} from "@app/components/Icons";

import { Faq } from "./Faq";
import { Typing } from "./Typing";

import processImage from "@assets/images/process.png";

const Home = () => {
  const [selectOs, setSelectOs] = useState("Both");

  return (
    <main>
      <section className="hero">
        <div className="hero_left">
          <div className="hero_pre">
            <Play />
            <Phone />
            <Smile />
          </div>
          <h1 className="hero_h1">App Store ready Godot games in minutes.</h1>
          <p className="hero_p">
            Focus on building great games while we take care of getting them
            shipped to the App Store.{" "}
          </p>
          <div className="hero_buttons grid grid--2col">
            <Button
              size="md"
              className="_mt10"
              variant="secondary"
              href="/docs"
            >
              Read the Docs
            </Button>

            <Button
              size="md"
              className="_mt10"
              variant="primary"
              href="https://npmjs.com/package/shipthis"
            >
              Get ShipThis
            </Button>
          </div>
        </div>
        <div className="hero_right">
          <Typing />
        </div>
      </section>

      <section className="features grid grid--2col">
        <div>
          <h2>Cloud build for iOS without Xcode</h2>
          <p>
            ShipThis manages your iOS certificates, provisioning profiles, and
            API keys for you. No need to install Xcode or mess with the Apple
            Developer Portal.
          </p>
          <p>
            With one command{" "}
            <span className="terminal">shipthis game ship</span> your game will
            be built on our servers and uploaded to the App Store.
          </p>
          <div className="grid grid--2col _mt10">
            <GodotLogo />
            <Apple fill="white" className="_mr10" width={42} height={42} />
          </div>
        </div>
        <div>
          <h2>Features</h2>
          <ul>
            <li>Guided Setup</li>
            <li>Build with one command</li>
            <li>Credentials are managed for you</li>
            <li>Monitor builds in the dashboard</li>
            <li>View error logs and build history</li>
            <li>Integrates with existing CI</li>
          </ul>
        </div>
      </section>
    </main>
  );
};

export { Home };
