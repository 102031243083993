import React from "react";
import { useQuery } from "@tanstack/react-query";
import { cacheKeys, CLI_README_URL } from "@app/constants";

interface SeparatedItem {
  headline: string;
  rest: string;
  title: string;
  titleParts: string[];
}

type HierarchyItem = string;

interface Separated {
  commands: SeparatedItem[] | {};
  hierarchy: HierarchyItem[] | {};
}

export const useCliReadMe = () => {
  const separate = (data: string): Separated => {
    try {
      // Find main section
      const comLeft = data.indexOf("<!-- commands -->");
      const comRight = data.indexOf("<!-- commandsstop -->", comLeft + 1);
      const commandsSection = data.substring(comLeft + 1, comRight);

      // Find commands -- that start with shipthis
      const commandsListRaw = commandsSection
        .split("## ")
        .filter((i) => i.startsWith("`shipthis"));

      // Find command title by location and the rest
      const subCommands = {};
      const commandsListClean = commandsListRaw.map((text: string) => {
        const titleTickLeft = text.indexOf("`");
        const titleTickRight = text.indexOf("`", titleTickLeft + 1);
        const title = text.substring(titleTickLeft + 1, titleTickRight);
        const titleParts = title.split(" "); // ['shipthis', 'apple', 'login']
        const command = titleParts[1];
        const param = titleParts[2];
        const headline = title.split("shipthis")?.[1];
        const rest = text.slice(titleTickRight + 1).trim();
        const description = rest.split("```")?.[0];
        const code = rest.split("```")?.[1]?.trim();
        // Collect commands and subcommands for hierarchy -- Sry UGLY 🤬
        if (!subCommands[command]) {
          subCommands[command] = [param];
        } else {
          if (subCommands[command].indexOf(param) === -1) {
            subCommands[command] = [...subCommands[command], param];
          }
        }
        return {
          title,
          titleParts,
          headline,
          description,
          code,
          rest
        };
      });

      // Compile a list of unique commands so that we can show a
      // navigation hierarchy on the sidebar
      const uniqueCommands = commandsListClean.reduce((acc, cur) => {
        const command = cur.title.split(" ")?.[1];
        if (Object.keys(acc).indexOf(command) > -1) {
          const r = acc;
          r[command] = [...r[command], cur];
          return r;
        } else {
          const r = acc;
          r[command] = [cur];
          return r;
        }
      }, {});
      return {
        commands: uniqueCommands,
        hierarchy: subCommands
      };
    } catch (error) {
      console.warn(error);
    }
  };

  return useQuery({
    queryKey: cacheKeys.docs(CLI_README_URL),
    queryFn: () =>
      fetch(CLI_README_URL)
        .then((response) => response.text())
        .then((md) => separate(md)),
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60
  });
};
