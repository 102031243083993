import axios from "axios";
import { useMutation } from "@tanstack/react-query";

import { API_URL, cacheKeys } from "@app/constants";
import { queryClient, setCurrentJWT } from "@app/utils/query";
import { ExchangeRequest, Self } from "@app/types";

export async function exchangeOTPForSelf(
  request: ExchangeRequest
): Promise<Self> {
  try {
    const response = await axios.post(`${API_URL}/auth/exchange`, request);
    return response.data as Self;
  } catch (error) {
    console.warn("exchangeOTPForSelf Error", error);
    throw error;
  }
}

export const useExchangeMutation = () => {
  return useMutation({
    mutationFn: exchangeOTPForSelf,
    onSuccess: async (data: Self) => {
      setCurrentJWT(data.jwt);
      queryClient.setQueryData(cacheKeys.isSignedIn, true);
      queryClient.setQueryData(cacheKeys.self, data);
    },
  });
};
