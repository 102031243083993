import React, { createContext } from "react";
import classNames from "classnames";
import { Apple } from "../Icons";

interface Props extends React.ComponentProps<"div"> {
  label?: string;
  isInvalid?: boolean;
  children: React.ReactNode;
  icon?: React.ReactNode;
}

const Context = createContext({
  isInvalid: false
});

const FormControl = ({
  className,
  children,
  icon,
  label,
  isInvalid
}: Props) => {
  return (
    <Context.Provider value={{ isInvalid }}>
      <label className={classNames("formItem", className)}>
        <div className="formItem-label">
          <div className="_flex">
            {icon}
            {label && <div>{label}</div>}
          </div>
          <Apple
            width={18}
            height={18}
            fill="white"
            style={{ position: "relative", top: -3 }}
          />
        </div>
        {children}
      </label>
    </Context.Provider>
  );
};

export { FormControl };
