import React from "react";

import { Project } from "@app/types";
import { Button, Chevron, Download, Log, Timeline } from "@app/components";
import { useProject } from "@app/utils/query";

interface Props {
  id?: any;
  projectId: Project["id"];
}

const GameBuilds = ({ id = "3hfioueshgsdg", projectId }: Props) => {
  const { data: project } = useProject(projectId);

  return (
    <div className="build">
      <div className="build-header" onClick={() => {}}>
        <span className="build-id">
          <Chevron width={18} stroke="white" />
          <span>#{id?.substring(0, 5)}</span>
        </span>
        <span>
          <span style={{ opacity: 0.5 }}>xxx</span>
          <span className="_ml5">---</span>
        </span>
        {/* <span className="job-updated _flex _flex-center">
          <Clock stroke="white" width={14} className="_mr5" />
          {dateUpdated.toLocaleString(DateTime.DATE_SHORT)}
        </span>
        <span className="_flex _flex-center">
          {status === JobStatus.PROCESSING ||
            (isSubscribed && <Spinner className="_mr10" width={12} />)}
          <Status variant={STATE_COLORS[status]}>
            {status}
          </Status>
        </span> */}
        <Button onClick={() => {}} size="sm" variant="dark">
          <span className="_mr5">Download</span>
          <Download width={16} stroke="#95a0aa" />
        </Button>
      </div>
    </div>
  );
};

export { GameBuilds };
