import React from "react";
import classNames from "classnames";

interface Props extends React.ComponentProps<"div"> {
  isShowing: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  clickAwayRef?: any;
}

const PopOver = ({
  isShowing,
  onClose,
  className,
  children,
  clickAwayRef,
  ...props
}: Props) => {
  if (!isShowing) return null;

  return (
    <div
      className={classNames("popOver", className)}
      ref={clickAwayRef}
      {...props}
    >
      {children}
    </div>
  );
};

export { PopOver };
