import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Avatar, ShortName, Spinner } from "@app/components";
import { useProjects } from "@app/utils/query";

import { Header } from "../_Shared";

export const GameList = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useProjects();

  console.log(data);

  return (
    <>
      <Header title="Games" />
      <main className="admin-main">
        <section>
          {isLoading && <Spinner />}
          <div className="grid game__grid">
            {data?.projects?.map(({ id, name, details }) => (
              <Link key={id} className="game" to={`/games/${id}`}>
                <ShortName name={name} className="game-shortName" />
                <div>
                  <NavLink to={`/games/${id}`} className="game-link">
                    {name} <span>[Build: {details?.buildNumber}]</span>
                  </NavLink>
                  <div className="game-details">{details?.iosBundleId}</div>
                  <Avatar size={28} className="game-avatar" />
                </div>
              </Link>
            ))}
          </div>
        </section>
      </main>
    </>
  );
};
