import React, { useEffect, useRef, useState } from "react";
import useMeasure from "react-use-measure";
import { useFormik } from "formik";
import { animated } from "@react-spring/web";

import { useSelf } from "@app/utils/query";

import { useFeedbackAnimation } from "./Hooks/useFeedbackAnimation";
import { OpenButton } from "./Components/OpenButton";
import { CloseButton } from "./Components/CloseButton";
import { EnterDetails as EnterDetailsPage } from "./Pages/EnterDetails";
import { ExtraDetails as ExtraDetailsPage } from "./Pages/ExtraDetails";
import { Done as DonePage } from "./Pages/Done";
import { Widget } from "./Components/Widget";

import "./feedback.css";
import { API_URL } from "@app/constants";
import axios from "axios";

// TODO
// [x] Tags funk
// [x] Formik
// [x] Pager
// [x] Folders/Structure
// [x] Page 2 - extra details
// [x] restart at page 1 ( after finish only )
// [x] Page 2 - thank you or close animation with scrolling thank you in the box?
// [x] Done - autoclose after x ms
// [-] Measure each page
// [x] populate additional data ( details object in formik? )
// [-] hover states
// [-] tag select animation (i think i have a problem with mutations)
// [>] mobile view, seems fine, need to test on real device
// [>] Needs message and email? validation
// [>] actual submit!

const Feedback = () => {
  const timerRef = useRef<any>();
  const [contentRef, { width, height }] = useMeasure();
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const { data: self } = useSelf();

  const {
    contentStyles,
    sectionStyles,
    buttonStyles,
    pageStyles,
    submitButtonStyles,
  } = useFeedbackAnimation(isOpen, width, height, page, isFinished);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: "",
      tags: "",
      email: self?.email || "",
      sendDetails: true,
      details: {},
    },
    async onSubmit(values) {
      const url = `${API_URL}/contact`;
      await axios.post(url, values);
      // TODO: clear form?
    },
  });

  useEffect(() => {
    if (self?.id) {
      formik.setFieldValue("details.userId", self.id);
    }
  }, [self]);

  const toggleFeedback = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    if (timerRef.current) clearTimeout(timerRef.current);
    if (isFinished) rewind();
  };

  const rewind = () => {
    setPage(0);
    setIsFinished(false);
  };

  const gotoNextPage = (e) => {
    e.preventDefault();
    setPage(page + 1);
  };

  const gotoPrevPage = (e) => {
    e.preventDefault();
    setPage(page - 1);
  };

  const gotoFinish = (e) => {
    e.preventDefault();
    setPage(2);
  };

  const finish = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    gotoFinish(e);
    setIsFinished(true);
    timerRef.current = setTimeout(toggleFeedback, 3000, e);
  };

  return (
    <Widget isOpen={isOpen} style={sectionStyles}>
      <OpenButton onClick={toggleFeedback} style={buttonStyles} />
      <animated.div className="feedback-contentWrap" style={contentStyles}>
        <div ref={contentRef} className="feedback-content">
          {/* Page 1 */}
          <EnterDetailsPage
            style={pageStyles}
            formik={formik}
            finish={finish}
            gotoNextPage={gotoNextPage}
            submitButtonStyles={submitButtonStyles}
          />
          {/* Page 2 */}
          <ExtraDetailsPage
            style={pageStyles}
            formik={formik}
            gotoPrevPage={gotoPrevPage}
            gotoNextPage={gotoNextPage}
            finish={finish}
            submitButtonStyles={submitButtonStyles}
          />
          {/* Page 3 */}
          <DonePage style={pageStyles} isVisible={page === 2} />
        </div>
        <CloseButton onClick={toggleFeedback} />
      </animated.div>
    </Widget>
  );
};

export { Feedback };
