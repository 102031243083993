import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Logo } from "@app/components";
import { useExchangeMutation } from "@app/utils/query";
import { getErrorMessage } from "@app/utils/errors";
import { ExchangeRequest, ExchangeURLParams } from "@app/types";
import { Footer } from "../_Shared";

export const Exchange = () => {
  /**
   * This page is visited by users of the CLI tool when they run the "dashboard"
   * command. Here we swap the single use 'otp' and 'userId' params for a JWT.
   */
  const [error, setError] = useState<undefined | string>();
  const exchangeMutation = useExchangeMutation();
  const navigate = useNavigate();

  const handlePageLoad = useCallback(async () => {
    try {
      const queryString = window.location.search;
      const query: ExchangeURLParams = Object.fromEntries(
        new URLSearchParams(queryString)
      );
      if (!query.userId || !query.otp) throw new Error("Missing userId or otp");
      const req = { userId: query.userId, otp: query.otp } as ExchangeRequest;
      await exchangeMutation.mutateAsync(req);
      navigate(query.destination || "/dashboard");
    } catch (error) {
      console.warn(error);
      setError(getErrorMessage(error));
    }
  }, [exchangeMutation, navigate]);

  useEffect(() => {
    handlePageLoad();
  }, [handlePageLoad]);

  return (
    <main>
      <section className="auth">
        <div className="_flex _flex-center _flex-spaceBetween">
          <Logo />
          <h1 className="auth_h1">Login</h1>
        </div>
        <p className="auth_error">{error}</p>
      </section>
      <Footer />
    </main>
  );
};
