import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useFindJobById, useProject } from "@app/utils/query";
import { Button, Log, Spinner, Tabs } from "@app/components";
import { JobLogEntry, Project, JobStatus } from "@app/types";

import { JobHeader } from "./Header";
import { Header } from "../../_Shared";

type Params = {
  projectId?: Project["id"];
  jobId?: JobLogEntry["id"];
};

const GameJobFull = () => {
  const { projectId, jobId } = useParams<Params>();
  const { data: project, isLoading } = useProject(projectId);
  const { foundJob, isSearching } = useFindJobById(projectId, jobId);
  const isSubscribed =
    !isSearching &&
    [JobStatus.PENDING, JobStatus.PROCESSING].includes(foundJob?.status);

  if (!foundJob || isLoading) return <Spinner />;

  return (
    <>
      <Header title="Games" />
      <main className="admin-main">
        <div className="job">
          <JobHeader
            job={foundJob}
            project={project}
            onSelect={() => {}}
            isSubscribed={isSubscribed}
          />
          <Log
            projectId={projectId}
            jobId={jobId}
            isSubscribed={isSubscribed}
            showEntireLog
          />
        </div>
      </main>
    </>
  );
};

export { GameJobFull };
