import React from "react";
import helloInvent from "@assets/images/helloInvent.png";
import { NavLink } from "react-router-dom";

const Footer = () => (
  <footer className="footerHome">
    <ul className="footerHome_pagesMain">
      <li>
        <NavLink to="/">Home</NavLink>
      </li>
      <li>
        <NavLink to="/docs">Docs</NavLink>
      </li>
      <li>
        <NavLink to="/dashboard">Dashboard</NavLink>
      </li>
    </ul>
    {/* 
    <ul className="footerHome_pagesLegal">
      <li>
        <NavLink to="/terms">Terms</NavLink>
      </li>
      <li>
        <NavLink to="/privacy">Privacy</NavLink>
      </li>
    </ul>
    */}
    <a href="//helloinvent.com" className="footerHome_helloInvent">
      <img
        src={helloInvent}
        width="142.31"
        height={54.95}
        alt="Hello Invent Logo"
      />
    </a>
    <div className="footerHome_copyright">
      <b>Copyright Hello Invent © 2024</b>
      <br />
      All rights reserved
    </div>
  </footer>
);

export { Footer };
