import React, { useEffect } from "react";
import classNames from "classnames";
import { Link, useParams, useSearchParams } from "react-router-dom";

interface Props<T> extends React.ComponentProps<"div"> {
  tabs: T;
  selectedTab: T;
  setSelectedTab: React.Dispatch<React.SetStateAction<T>>;
}

const Tabs = ({
  selectedTab,
  setSelectedTab,
  tabs: TABS,
  ...props
}: Props<typeof TABS>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { jobId, projectId, tab } = useParams<any>();

  return (
    <div className="tabs" {...props}>
      {Object.keys(TABS).map((t) => (
        <Link
          key={t}
          // TODO: using 'to' b0rks the back button
          to={`/games/${projectId}/${t.toLocaleLowerCase()}`}
          className={classNames("tab", {
            "tab--active": selectedTab === t
          })}
          onClick={() => setSelectedTab(t)}
        >
          {t?.toLowerCase()}
        </Link>
      ))}
    </div>
  );
};

export { Tabs };
