import React from "react";
import { Link } from "react-router-dom";

import { Section, Spinner } from "@app/components";
import { useSelf } from "@app/utils/query";

import { Header } from "../_Shared";
import { MyRecentJobs } from "./MyRecentJobs";
import { MyRecentProjects } from "./MyRecentProjects";

const Dashboard = () => {
  const { isLoading, data: self } = useSelf();

  return (
    <>
      <Header title="Dashboard" />

      <main className="admin-main">
        <Section>
          {isLoading && <Spinner />}
          {self && <p className="_mb-0">Welcome, {self.email}</p>}
        </Section>
        <Section>
          <h2 className="admin-section__title">Quick Links</h2>
          <div className="grid grid--2col">
            <Link to="/credentials">Your Developer Credentials</Link>
            <Link to="/games">Your Games</Link>
          </div>
        </Section>

        <div className="grid grid--2col admin__grid">
          <MyRecentProjects />
          <MyRecentJobs />
        </div>
      </main>
    </>
  );
};

export { Dashboard };
