import React from "react";
import { Outlet } from "react-router-dom";
import { Sparkles } from "@app/screens/Homepage/_Shared";

export const LayoutAuth = () => {
  return (
    <>
      <div className="page-wrapper">
        <div className="page page--auth">
          <Sparkles />
          <div className="page-content page-content--auth">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
