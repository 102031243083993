import { DateTime } from "luxon";

// How the current user looks from the API
export interface Self {
  id: string;
  email: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  jwt: string;
}

// Submit this to the /auth/exchange to get a Self back
export interface ExchangeRequest {
  userId: string;
  otp: string;
}

// Params received by the /exchange page which then submits the ExchangeRequest
// URL params so all attributes are optional
export interface ExchangeURLParams extends Partial<ExchangeRequest> {
  destination?: string;
}

// URL params received by the Google Redirect destination
export interface GoogleOAuthRedirectResponse {
  code?: string;
  error?: string;
}

// What we send to /me/google/connect to swap a code for a token and persist it
export interface GoogleOAuthPersistTokenRequest {
  code: string;
  redirectUri: string;
}

// What we receive from /me/google/connect - tells us which page to redirect to
export interface GoogleOAuthPersistTokenResponse {
  projectId: string;
}

// Response from /auth/google
export interface GoogleAuthResponse {
  url: string;
}

export interface Project {
  id: string;
  name: string;
  details: ProjectDetails;
  createdAt: DateTime;
  updatedAt: DateTime;
}

export enum CredentialPlatform {
  IOS = "IOS",
  ANDROID = "ANDROID"
}

export enum CredentialType {
  CERTIFICATE = "CERTIFICATE",
  KEY = "KEY"
}

interface BaseCredential {
  id: string;
  platform: CredentialPlatform;
  type: CredentialType;
  identifier: string;
  bucketName: string;
  key: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  url: string;
  serialNumber: string;
  isActive: boolean;
}

export interface ProjectCredential extends BaseCredential {
  identifier: string;
  projectId: string;
}

export interface UserCredential extends BaseCredential {
  details?: object;
  userId: string;
}

export interface AndroidSetupStatus {
  hasSignedIn: boolean;
  hasProject: boolean;
  hasServiceAccount: boolean;
  hasKey: boolean;
  hasUploadedKey: boolean;
  hasEnabledApi: boolean;
}

export enum JobType {
  IOS = "IOS",
  ANDROID = "ANDROID"
}

export enum JobStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED"
}

export interface Upload {
  id: string;
  userId: string;
  projectId: string;
  bucketName: string;
  key: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  url: string;
  details: UploadDetails;
}

export interface Job {
  id: string;
  type: JobType;
  status: JobStatus;
  createdAt: DateTime;
  updatedAt: DateTime;
  project?: Project;
  upload: Upload;
  details?: JobDetails; // old jobs don't have details
}

export enum JobStage {
  SETUP = "SETUP",
  EXPORT = "EXPORT",
  CONFIGURE = "CONFIGURE",
  BUILD = "BUILD",
  PUBLISH = "PUBLISH"
}

export enum LogLevel {
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR"
}

export interface JobLogEntry {
  id: string;
  level: LogLevel;
  stage: JobStage;
  jobId: string;
  message: string;
  details: object;
  sentAt: DateTime;
  createdAt: DateTime;
}

export type ProjectDetails = {
  iosBundleId?: string;
  androidPackageName?: string;
  buildNumber?: number;
  semanticVersion?: string;
  gameEngine?: string;
  gameEngineVersion?: string;
};

export type UploadDetails = {
  gitCommitHash?: string;
  gitBranch?: string;
  zipFileMd5?: string;
};

export type JobDetails = UploadDetails & ProjectDetails;
