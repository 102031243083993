import { JobType } from "@app/types/api";
import { SVGProps } from "react";
import { Apple } from "./Apple";
import { Android } from "./Android";

interface OsIcon extends SVGProps<SVGSVGElement> {
  type: JobType;
}

const OsIcon = ({ type, size = 24, ...props }) => {
  const Component = { IOS: Apple, ANDROID: Android }[type];
  return <Component {...props} width={size} height={size} />;
};

export { OsIcon };
