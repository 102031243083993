import React from "react";
import { NavLink } from "react-router-dom";
import { animated, useSpring } from "@react-spring/web";

import { Logo } from "@app/components";
import { useIsMobile } from "@app/utils/hooks";
import { hideSidebar, useSidebarState } from "@app/utils/emitters";
import { Overlay } from "./Overlay";

const Sidebar = () => {
  const isMobile = useIsMobile(1024);
  const { isShowing } = useSidebarState();

  const asideStyles = useSpring({
    left: isShowing ? 0 : -200,
    config: {
      tension: 250
    }
  });

  const hideSidebarOnMobile = () => {
    if (isMobile) hideSidebar();
  };

  return (
    <>
      <Overlay onPress={hideSidebar} isOpen={isShowing} />
      <animated.aside className="admin-sidebar" style={asideStyles}>
        <Logo
          linkTo="/dashboard"
          className="admin-logo"
          onClick={hideSidebarOnMobile}
        />
        <nav className="admin-nav">
          <ul>
            <li>
              <NavLink
                to="/credentials"
                className="admin-navLink"
                onClick={hideSidebarOnMobile}
              >
                Credentials
              </NavLink>
              <NavLink
                to="/games"
                className="admin-navLink"
                onClick={hideSidebarOnMobile}
              >
                Games
              </NavLink>
            </li>
          </ul>
        </nav>
      </animated.aside>
    </>
  );
};

export { Sidebar };
