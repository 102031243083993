import axios, { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { API_URL, cacheKeys } from "@app/constants";
import { castObjectDates } from "@app/utils/dates";
import { Project } from "@app/types";

import { getAuthHeaders } from "./useIsSignedIn";

export async function fetchProject(projectId: string): Promise<Project> {
  try {
    const headers = getAuthHeaders();
    const url = `${API_URL}/projects/${projectId}`;
    const response = await axios.get(url, { headers });
    return castObjectDates<Project>(response.data);
  } catch (error) {
    console.warn("fetchProject Error", error);
    throw error;
  }
}

export const useProject = (projectId: string): UseQueryResult<Project> => {
  return useQuery<Project, AxiosError>({
    queryKey: cacheKeys.project(projectId),
    queryFn: () => fetchProject(projectId),
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60
  });
};
