import React from "react";
import classNames from "classnames";
import { Bell } from "@app/components";

const Count = ({ count }: { count: number }) => {
  if (count > 0) return <div className="notifications_counter">{count}</div>;
};

const Notifications = () => {
  const count = 0;

  const className = classNames("admin__navLink--notifications", {
    "notifications--enabled": count > 0
  });

  return (
    <a href="#" className={className}>
      <Bell stroke="white" />
      <Count count={count} />
    </a>
  );
};

export { Notifications };
