import React from "react";
import classNames from "classnames";

const LevelDot = ({ level }) => {
  const color = {
    ERROR: "red",
    INFO: "blue"
  }[level];

  return <span className={classNames("levelDot", `levelDot--${color}`)} />;
};

export { LevelDot };
