import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { showSidebar } from "@app/utils/emitters";
import { Avatar, Notifications, Sidebar } from "@app/components";
import { AuthPopOver, useAuthPopOver } from "@app/components/PopOver/Auth";
import { useIsMobile } from "../../../utils/hooks";

interface Props {
  title?: string;
}

export const Header = ({ title }: Props) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile(1024);
  const { show: showPopOver, toggle } = useAuthPopOver();

  return (
    <header className="admin-header">
      <div className="_flex _flex-center">
        {isMobile && (
          <Link
            to="#"
            className="admin__navLink _flex _mr10"
            onClick={showSidebar}
          >
            <Sidebar
              width={22}
              height={22}
              stroke="#626873"
              className="admin-toggleSidebar"
            />
          </Link>
        )}
        <h1 className="admin-pageTitle">{title}</h1>
      </div>
      <div className="_flex _flex-center">
        <Notifications />
        <Avatar size={36} className="_ml5" onClick={toggle}>
          <AuthPopOver showPopOver={showPopOver} toggle={toggle} />
        </Avatar>
      </div>
    </header>
  );
};
