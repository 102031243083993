import React, { useEffect } from "react";

export const useScrollToAnchor = () => {
  useEffect(() => {
    if (!window.location.hash) return;
    const anchor = window.location.hash.slice(1);
    if (anchor) {
      const anchorEl = document.getElementById(anchor);
      if (anchorEl) {
        anchorEl.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [window.location.hash]);

  return null;
};
