import React, { useState } from "react";
import {
  Button,
  Section,
  TextInput,
  FormControl,
  Certificate,
  Key
} from "@app/components";
import { CredentialPlatform, CredentialType, Project } from "@app/types";
import { useProjectCredentials } from "@app/utils/query";

interface Props {
  projectId: Project["id"];
}

const { IOS, ANDROID } = CredentialPlatform;

const Icon = ({ type }: { type?: CredentialType }) => {
  if (!type) return null;
  const Component = type === CredentialType.CERTIFICATE ? Certificate : Key;
  return (
    <div className="formItem-icon">
      <Component width={14} height={14} />
    </div>
  );
};

const GameCredentials = ({ projectId }: Props) => {
  const { data, isLoading } = useProjectCredentials(projectId, IOS);

  console.log(data);

  return (
    <Section>
      {!isLoading &&
        data?.projectCredentials?.map(({ id, type, serialNumber }) => {
          return (
            <FormControl
              key={id}
              label="Mobile Provisioning Profile"
              icon={<Icon type={type} />}
            >
              <TextInput value={serialNumber} onChange={() => {}} isLocked />
            </FormControl>
          );
        })}
    </Section>
  );
};

export { GameCredentials };
