import React from "react";
import { DateTime } from "luxon";

import { Job, JobStatus, Project } from "@app/types";
import {
  Chevron,
  Spinner,
  STATE_COLORS,
  Status,
  Timeline,
  TimeAgo
} from "@app/components";

interface Props {
  job: Job;
  project: Project;
  isSubscribed: boolean;
  onSelect: () => void;
}

const JobHeader = ({ onSelect, project, job, isSubscribed }: Props) => {
  const { id, type, status, updatedAt } = job;

  // Jobs should start to all have these on the details - fallbacks are for old jobs
  const semanticVersion = job?.details?.semanticVersion || "0.0.1";
  const buildNumber = job?.details?.buildNumber || 1;

  return (
    <div className="job-header" onClick={onSelect}>
      <span className="job-id">
        <Chevron width={18} stroke="white" />
        <span>#{id?.substring(0, 5)}</span>
      </span>
      <span className="job-nameWrap">
        <span className="job-name">
          {project.name} {semanticVersion} [build: {buildNumber}]
        </span>
        <span className="job-type">
          {{ ANDROID: "Android", IOS: "iOS" }[type]}
        </span>
      </span>
      <Timeline
        projectId={project.id}
        jobId={job.id}
        className="job-timeLine"
      />
      <TimeAgo date={updatedAt} showIcon showAgo className="job-updated" />
      <span className="job-status">
        {status === JobStatus.PROCESSING ||
          (isSubscribed && <Spinner className="_mr10" width={12} />)}
        <Status variant={STATE_COLORS[status]}>{status}</Status>
      </span>
    </div>
  );
};

export { JobHeader };
